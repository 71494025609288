

















































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ScrollArea from '@/pages/P0918/ScrollArea.vue';
@Component({
  components: { ScrollArea }
})
export default class CouponUsed extends Vue {
  @Prop({ default: false })
  readonly hasBottomHr!: boolean;

  isOpenCoupon01 = false;
  isOpenCoupon02 = false;
  isOpenCoupon03 = false;
  isOpenCoupon04 = false;
}
