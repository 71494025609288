




















































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import ScrollArea from '@/pages/P0918/ScrollArea.vue';
@Component({
  components: { ScrollArea }
})
export default class AboutTicket extends Vue {}
