

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ScrollArea extends Vue {
  isScrolled = false;

  @Prop()
  readonly myClass?: string;

  mounted() {
    this.checkIfScrollable();
  }

  checkIfScrollable() {
    const scrollContainer = this.$refs.scrollContainer as HTMLElement;
    if (scrollContainer) {
      // スクロール可能な幅が表示幅と同じか小さい場合、スクロールバーは出ない
      if (scrollContainer.scrollWidth <= scrollContainer.clientWidth) {
        this.isScrolled = true; // スクロール不要なのでフラグをtrueに設定
      }
    }
  }

  handleScroll() {
    if (this.isScrolled) {
      return; // isScrolledがtrueの場合、処理をスキップする
    }

    const scrollContainer = this.$refs.scrollContainer as HTMLElement;
    if (scrollContainer) {
      const scrollLeft = scrollContainer.scrollLeft;
      if (scrollLeft > 0) {
        this.isScrolled = true; // フラグをtrueに設定
        scrollContainer.removeEventListener('scroll', this.handleScroll); // イベントリスナーを削除
      }
    }
  }
}
